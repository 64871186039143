<template>
  <div>
    <h1>Referral</h1>
    <md-table class="table" md-card>
      <md-table-row>
        <md-table-head md-numeric>S/N</md-table-head>

        <md-table-head>Name</md-table-head>
        <md-table-head>Status</md-table-head>
        <md-table-head>Bonus</md-table-head>
      </md-table-row>

      <md-table-row>
        <!-- <md-table-cell md-numeric>1</md-table-cell> -->
      </md-table-row>

      <md-table-row>
        <!-- <md-table-cell md-numeric>2</md-table-cell> -->
      </md-table-row>

      <md-table-row>
        <!-- <md-table-cell md-numeric>3</md-table-cell> -->
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: 'Referral',
};
</script>

<style scoped>
.table {
  width: 100%;
}

h1 {
  font-size: 2.25rem;
  font-family: 'Fira Code';
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
}
</style>
