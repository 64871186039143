import Vue from 'vue';
import App from './App.vue';

import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import VueRouter from 'vue-router';
import routes from './routes';

import VueClipboard from 'vue-clipboard2';
import store from './store';

Vue.config.productionTip = false;

require('./store/subscriber');

export const bus = new Vue();

VueClipboard.config.autoSetContainer = true 

Vue.use(VueMaterial);
Vue.use(VueRouter);
Vue.use(VueClipboard);

export const router = new VueRouter({
  routes,
  mode: 'history',
});

store.dispatch('attempt', localStorage.getItem('token')).then(() => {
  store.dispatch('getAdmin');
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
