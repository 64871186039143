<template>
  <div :class="{ nonav: checkRouter() }" id="app">
    <div v-if="!checkRouter()" class="navbar">
      <Navbar />
    </div>

    <div :class="{ goup: checkRouter() }" class="content-body">
      <router-view></router-view>
    </div>
    <div v-if="!checkRouter()" class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
  },

  methods: {
    checkRouter() {
      return (
        ['/login', '/register', '/account'].includes(
          this.$router.currentRoute.fullPath
        ) || this.$router.currentRoute.fullPath.startsWith('/account')
      );
    },
  },
};
</script>

<style>
.navbar {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.content-body {
  top: 66px;
  position: relative;
  z-index: 1;
}

.nonav {
  background: #fff;
}

.goup {
  top: 0;
}
</style>
