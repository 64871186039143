<template>
  <div>
    <section id="plans">
      <div class="plan-head">
        <h1 class="heading">
          Our Plan
        </h1>

        <p>
          Our aim is to reduce our negative social, economic and environmental
          footprints while enhancing the overall value we create as a leading
          brand
        </p>
      </div>

      <div class="plans">
        <md-card class="plan">
          <md-card-header class="starter">
            <span>Starter</span>
          </md-card-header>

          <md-card-content>
            <div class="details">
              <div class="list-item">
                <h2>$1000</h2>
              </div>
              <md-divider></md-divider>

              <div class="list-div">
                <span>30% Investment</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Personal Manager</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Email Support</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>24/7 support</span>
              </div>

              <md-divider></md-divider>
            </div>
          </md-card-content>

          <router-link to="" class=" border">START NOW</router-link>
        </md-card>

        <md-card class="plan">
          <md-card-header class="starter">
            <span>Basic</span>
            <p>Most popular</p>
          </md-card-header>

          <md-card-content>
            <div class="details">
              <div class="list-item">
                <h2>$10,000</h2>
              </div>
              <md-divider></md-divider>

              <div class="list-div">
                <span>30% Investment</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Personal Manager</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Email Support</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>24/7 support</span>
              </div>

              <md-divider></md-divider>
            </div>
          </md-card-content>

          <router-link to="" class="background">START NOW</router-link>
        </md-card>

        <md-card class="plan">
          <md-card-header class="starter ">
            <div class="star">
              <span>Pro</span> <md-icon class="md-icon">star_border</md-icon>
            </div>
          </md-card-header>

          <md-card-content>
            <div class="details">
              <div class="list-item">
                <h2>$10,000</h2>
              </div>
              <md-divider></md-divider>

              <div class="list-div">
                <span>30% Investment</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Personal Manager</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Email Support</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>24/7 support</span>
              </div>

              <md-divider></md-divider>
            </div>
          </md-card-content>

          <router-link to="" class="background">START NOW</router-link>
        </md-card>

        <md-card class="plan">
          <md-card-header class="starter">
            <span>Executive</span>
          </md-card-header>

          <md-card-content>
            <div class="details">
              <div class="list-item">
                <h2>$50,000</h2>
              </div>
              <md-divider></md-divider>

              <div class="list-div">
                <span>75% Investment</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Executive Manager</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Phone & email Support</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>24/7 support</span>
              </div>

              <md-divider></md-divider>
            </div>
          </md-card-content>

          <router-link to="" class=" border">START NOW</router-link>
        </md-card>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Plans',
};
</script>

<style scoped>
#plans {
  background: rgb(18, 29, 51);
  padding-bottom: 10vh;
  color: #fff;
}

.plans {
  text-align: center;
}

.border {
  border: 1px solid rgba(63, 81, 181, 0.5);
  color: rgba(63, 81, 181, 0.5);
}

.background {
  background-color: #3f51b5;
  border: none;
  color: #fff !important;
}

.starter {
  padding: 16px;
  font-size: 1.6rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  background-color: #eeeeee;
  border-radius: 4px;
}

.details h2 {
  font-size: 2.125rem;
  font-family: Roboto;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  margin: 16px 0 16px 0;
}

.list-div {
  padding: 10px 0;
}

.list-div span {
  font-size: 19.2px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
}
.plan {
  padding-bottom: 10px;
  margin-bottom: 15px !important;
  border-radius: 4px;
}

.plan a {
  display: block;
  padding: 7px 15px;
  width: 95%;
  margin: 0 auto;
}

.md-icon {
  margin: 0;
  float: right;
}

.starter p {
  font-size: 1rem;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 4px;
}

.plan-head {
  padding: 64px 0px 48px;
}

.plan-head h1 {
  margin: 0;
  margin-bottom: 0.35em;
}

@media (min-width: 768px) {
  .numbers,
  .plans {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    grid-gap: 15px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }

  .plans {
    padding: 3rem 3.5rem;
  }

  #plans p {
    width: 60%;
    margin: 0 auto;
    font-size: 1.3rem;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
  }
}
</style>
