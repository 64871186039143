<template>
  <div>
    <div class="overlay"></div>
    <md-tabs class="md-primary tabbs" md-alignment="centered">
      <md-tab class="tab" id="tab-home" md-label="Profile">
        <div class="search-input">
          <div v-if="currentUser.role === 'admin'" class="admin">
            <img :src="currentUser.qrCode" alt="" srcset="" />

            <input
              style="display:none"
              type="file"
              @change="uploadFile"
              ref="fileInput"
            />
            <button @click="$refs.fileInput.click()" class="upload-btn">
              <div class="btn-content">
                <md-progress-spinner
                  v-show="uploading"
                  :md-diameter="20"
                  :md-stroke="3"
                  md-mode="indeterminate"
                ></md-progress-spinner>
                <span> Upload QrCode</span>
              </div>
            </button>
          </div>

          <h1>Account Details</h1>
          <div class="input-container">
            <input
              disabled
              class="input"
              type="text"
              required
              :value="currentUser.name.toUpperCase()"
            />
            <span class="label">Name </span>
          </div>

          <div class="input-container">
            <input
              disabled
              class="input"
              type="text"
              required
              :value="currentUser.email"
            />
            <span class="label">Email </span>
          </div>

          <div class="input-container">
            <input
              class="input"
              type="text"
              required
              v-model="payload.walletAddress"
            />
            <span class="label">Wallet Address </span>
          </div>

          <div class="input-container">
            <input disabled class="input" type="text" required value="BASIC" />
            <span class="label">Plan </span>
          </div>

          <div class="input-container">
            <input disabled class="input" type="text" required :value="currentUser.username" />
            <span class="label">Username </span>
          </div>

          <div class="input-container">
            <input class="input" disabled type="text" required :value="currentUser.phone" />
            <span class="label">Mobile Number </span>
          </div>

          <div class="input-container">
            <input
              disabled
              class="input"
              type="text"
              required
              :value="currentUser.gender.toUpperCase()"
            />
            <span class="label">Gender </span>
          </div>

          <div class="input-container">
            <input
              :value="currentUser.country.toUpperCase()"
              disabled
              class="input"
              type="text"
              required
            />
            <span class="label">Country </span>
          </div>

          <md-button @click="sendDetails" class="md-raised md-primary withdraw"
            >Submit</md-button
          >
        </div>
      </md-tab>

      <md-tab class="tab" id="tab-pages" md-label="Security">
        <form class="search-input">
          <h1>Change Password</h1>
          <div class="input-container">
            <input
              class="input"
              type="password"
              required
              value="CEDAR DANIEL"
              v-model="password.currentPassword"
            />
            <span class="label">Old Password </span>
          </div>

          <div class="input-container">
            <input
              v-model="password.newPassword"
              class="input"
              type="password"
              required
              value="ced@ced.com"
            />
            <span class="label">New Password </span>
          </div>

          <div class="input-container">
            <input
              v-model="password.newConfirmPassword"
              class="input"
              type="password"
              required
              value="BASIC"
            />
            <span class="label">Confirm New Password </span>
          </div>

          <md-button
            @click="sendPassword"
            class="md-raised md-primary withdraw"
          >
            <div class="btn-content">
              <md-progress-spinner
                v-show="loading"
                :md-diameter="20"
                :md-stroke="3"
                md-mode="indeterminate"
              ></md-progress-spinner>
              <span> CHANGE</span>
            </div></md-button
          >
        </form>
      </md-tab>

      <md-tab class="tab" id="tab-posts" md-label="Bank">
        <form class="search-input bank">
          <h1>Bank Details</h1>
          <div class="input-container">
            <input class="input" type="text" required />
            <span class="label">Account Name </span>
          </div>

          <div class="input-container">
            <input class="input" type="text" required />
            <span class="label">Account Number </span>
          </div>

          <div class="input-container">
            <input class="input" type="text" required />
            <span class="label">Account Type </span>
          </div>

          <div class="input-container">
            <input class="input" type="text" required />
            <span class="label">Bank Name * </span>
          </div>

          <div class="input-container">
            <input class="input" type="text" required />
            <span class="label">Bank Code * </span>
          </div>

          <md-button class="md-raised md-primary withdraw">CHANGE</md-button>
        </form>
      </md-tab>
    </md-tabs>

    <md-snackbar
      :md-position="position"
      :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="showSnackbar"
      md-persistent
    >
      <span>Update Successful!</span>
      <md-button class="md-primary" @click="showSnackbar = false"
        >Close</md-button
      >
    </md-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { bus } from '../main';
export default {
  name: 'Settings',

  data: () => ({
    payload: {
      phone: null,
      walletAddress: '',
    },

    uploading: false,
    loading: false,

    password: {
      currentPassword: '',
      newPassword: '',
      newConfirmPassword: '',
    },

    showSnackbar: false,
    position: 'center',
    duration: 4000,
    isInfinity: false,
  }),

  methods: {
    ...mapActions(['updateMe', 'updatePassword']),

    sendDetails() {
      console.log(this.phone);
      this.updateMe({ payload: this.payload });
    },

    sendPassword() {
      this.loading = true;
      this.updatePassword(this.password);
    },

    uploadFile(event) {
      this.uploading = true;
      //Get File From Event
      const selectedFile = event.target.files[0];

      //Create new FormData Object
      const fd = new FormData();

      //Append File and file name to the new FormData
      fd.append('file', selectedFile, selectedFile.name);

      fd.append('folder', 'QrCode');

      this.updateMe({ payload: fd });
    },
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  mounted() {
    this.payload.phone = this.currentUser.phone;
    this.payload.walletAddress = this.currentUser.walletAddress;

    bus.$on('stopLoading', () => {
      this.uploading = false;
      this.loading = false;
      this.showSnackbar = true;
    });
  },
};
</script>

<style scoped>
.md-primary {
  background-color: #3f51b5 !important;
}

.search-input {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  margin: 1.5rem;
}

.input-container {
  display: flex;
  position: relative;
  height: 75px;
  width: 100%;
  padding-left: 5px;
  margin-right: 10px;
  align-items: center;
}

.input {
  height: 55px;
  width: 100%;
  font-size: 14px;
  caret-color: #990000;
  padding-left: 10px;
  border-radius: 4px;
  border-style: solid;
  border-color: #c2c2c2;
  border-width: 0.5px;
  float: left;
  background: transparent;

  -webkit-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.input:hover {
  border-color: black;
}

.input:valid + .label {
  top: 2px;
  left: 10px;
  font-size: 12px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.input:focus {
  padding-left: 9px;
  border-width: 2px;
  border-color: #3f51b5;
  outline: none;
}

.input:focus + .label {
  top: 2px;
  left: 10px;
  font-size: 12px;
  color: #3f51b5;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.search {
  float: right;
}

.label {
  position: absolute;
  pointer-events: none;
  top: 2px;
  left: 10px;
  font-size: 14px;
  color: #666666;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;

  -webkit-transition: all 0.5s; /* For Safari 3.0 to 6.0 */
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s; /* For modern browsers */
}

h1 {
  font-size: 2.25rem;
  font-family: 'Fira Code';
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  display: inline;
}

.container {
  text-align: center;
  margin-top: 4.5rem;
}

h3 {
  font-size: 1.5rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

.md-avatar-icon {
  margin: 8px;
  display: inline-block;
  text-align: center;
  padding-top: 8px;
  background-color: blue !important;
}

.withdraw,
.upload-btn {
  background-color: #303f9f !important;
  display: block;
  width: 100%;
  margin: 10px 0;
}

.upload-btn {
  border: none;
  color: #fff;
  padding: 10px;
}

p {
  font-family: initial;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

h1 {
  font-size: 1.5rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

.md-tabs.md-theme-default.md-primary .md-tabs-navigation {
  background-color: #3f51b5 !important;
}

.bank .input:valid + .label {
  top: 2px;
  left: 10px;
  font-size: 12px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.bank .input:focus {
  padding-left: 9px;
  border-width: 2px;
  border-color: #3f51b5;
  outline: none;
}

.bank .input:focus + .label {
  top: 2px;
  left: 10px;
  font-size: 12px;
  color: #3f51b5;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.bank .label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 27px;
  font-size: 14px;
  color: #666666;

  -webkit-transition: all 0.5s; /* For Safari 3.0 to 6.0 */
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s; /* For modern browsers */
}

.overlay {
  height: 50px;
  width: 100%;
  background: #3f51b5;
  position: relative;
  top: 48px;
  z-index: 2;
}

.tabbs {
  z-index: 1;
}
</style>
