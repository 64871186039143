<template>
  <div>
    <h1>Transactions</h1>
    <md-table class="table" md-card>
      <md-table-row>
        <md-table-head md-numeric>S/N</md-table-head>

        <md-table-head>Type</md-table-head>
        <md-table-head>Status</md-table-head>
        <md-table-head>Amount</md-table-head>
      </md-table-row>

      <md-table-row v-for="(transaction, index) in transactions" :key="transaction._id">
        <md-table-cell md-numeric>{{index + 1}}</md-table-cell>
        <md-table-cell >{{transaction.type}}</md-table-cell>
        <md-table-cell>Successful</md-table-cell>
        <md-table-cell md-numeric>{{transaction.amount}}</md-table-cell>
      </md-table-row>

      <md-table-row>
        <!-- <md-table-cell md-numeric>2</md-table-cell> -->
      </md-table-row>

      <md-table-row>
        <!-- <md-table-cell md-numeric>3</md-table-cell> -->
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Referral',
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions(['getTransactions']),

  },
  async created() {
    this.transaction = await this.getTransactions();
    console.log('transactions', this.transactions)
  },
  computed: {
    ...mapGetters(['errorMsg', 'transactions']),
  },
};
</script>

<style scoped>
.table {
  width: 100%;
}

h1 {
  font-size: 2.25rem;
  font-family: 'Fira Code';
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
}
</style>
