import store from './store';

import Home from './components/Home.vue';
import Contact from './components/Contact.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import Plans from './components/Plans.vue';
import KycPolicy from './components/KycPolicy.vue';
import PaymentPolicy from './components/PaymentPolicy.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import Account from './components/Account.vue';
import Deposit from './components/Deposit.vue';
import Dashboard from './components/Dashboard.vue';
import Withdrawal from './components/Withdrawal.vue';
import Referral from './components/Referral.vue';
import Support from './components/Support.vue';
import Settings from './components/Settings.vue';
import Transactions from './components/Transactions.vue';
import Users from './components/Users.vue';
import Receipts from './components/Receipts.vue';

export default [
  { path: '/', component: Home },
  { path: '/contact', component: Contact },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/plans', component: Plans },
  { path: '/kyc-policy', component: KycPolicy },
  { path: '/payment-policy', component: PaymentPolicy },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  {
    path: '/account',
    name: 'account',
    component: Account,
    beforeEnter: (to, from, next) => {
      if (store.state.auth.token) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      { path: 'deposit', component: Deposit },
      { path: 'dashboard', component: Dashboard },
      { path: 'withdrawal', component: Withdrawal },
      { path: 'referral', component: Referral },
      { path: 'support', component: Support },
      { path: 'settings', component: Settings },
      { path: 'transaction', component: Transactions },
      {
        path: 'users',
        component: Users,
        beforeEnter: (to, from, next) => {
          if (
            store.state.auth.token &&
            store.state.auth.user.role === 'admin'
          ) {
            next();
          } else {
            next('/');
          }
        },
      },

      {
        path: 'receipts',
        component: Receipts,
        beforeEnter: (to, from, next) => {
          if (
            store.state.auth.token &&
            store.state.auth.user.role === 'admin'
          ) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
];
