<template>
  <div>
    <div class="wrapper">
      <div>
        <h5>Deposit Using Blockchain Wallet</h5>
        <p>Deposit to Blockchain wallet code/address generated.</p>
        <md-button
          v-if="!isCopied"
          v-clipboard:copy="address"
          v-clipboard:success="displayAlert"
          class="md-raised md-primary copy"
          @click="copy"
          >COPY TO CLIPBOARD</md-button
        >

        <md-button
          v-if="isCopied"
          v-clipboard:copy="address"
          v-clipboard:success="displayAlert"
          class="md-raised md-primary copy"
          >COPIED</md-button
        >
        <p>{{ admin.walletAddress }}</p>
        <img :src="admin.qrCode" alt="" srcset="" />
      </div>

      <!-- <div>
        <h5>Deposit Using These other Methods</h5>
        <div class="buttons">
          <div>
            <router-link
              class="link"
              to="https://play.google.com/store/apps/details?id=com.polehin.android"
            >
              <i class="fab fa-google-play"></i> WALLET
            </router-link>

            <router-link
              class="link"
              to="https://play.google.com/store/apps/details?hl=en&id=co.bitx.android.wallet"
            >
              <i class="fab fa-google-play"></i> LUNO
            </router-link>
          </div>

          <div class="make-flex ">
            <router-link class="link pink" to="https://crypto.com/">
              <i class="fas fa-globe-africa"></i> CRYPTO
            </router-link>

            <router-link class="link pink" to="https://www.bitcoin.com/">
              <i class="fas fa-globe-africa"></i> BITCOIN.COM
            </router-link>
          </div>

          <div class="make-flex">
            <router-link
              class="link"
              to="https://play.google.com/store/apps/details?id=com.coinbase.android&hl=en"
            >
              <i class="fab fa-google-play"></i> COINBASE
            </router-link>

            <router-link
              class="link"
              to="https://play.google.com/store/apps/details?id=com.binance.dev&hl=en&gl=US"
            >
              <i class="fab fa-google-play"></i> BINANCE
            </router-link>
          </div>

          <div class="make-flex">
            <router-link
              class="link"
              to="https://play.google.com/store/apps/details?id=com.squareup.cash"
            >
              <i class="fab fa-google-play"></i> CASH APP
            </router-link>

            <router-link
              class="link"
              to="https://itunes.apple.com/us/app/square-cash/id711923939?pt=302818&mt=8&ct=cash-web-home"
            >
              <i class="fab fa-apple"></i> CASH APP
            </router-link>
          </div>
        </div>
      </div> -->

      <div class="card-div">
        <md-card>
          <md-card-header>
            <div class="md-title">UPLOAD RECEIPT</div>
          </md-card-header>

          <md-card-content class="card-content">
            <md-field>
              <label>Enter Amount Deposited</label>
              <md-input v-model="amount"></md-input>
            </md-field>

            <div class="md-layout-item">
              <md-field>
                <label for="plan">Choose Plan</label>
                <md-select v-model="plan" name="plan" id="plan">
                  <md-option value="starter">Starter</md-option>
                  <md-option value="basic">Basic</md-option>
                  <md-option value="professional">Professional</md-option>
                  <md-option value="executive">Executive</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="image-div">
              <img
                src="../../public/images/upload.3ecc9c46.svg"
                alt=""
                srcset=""
              />
            </div>

            <div v-if="url">
              <img :src="url" alt="" srcset="" />
            </div>

            <div class="file">
              <input
                style="display:none"
                type="file"
                @change="uploadFile"
                ref="fileInput"
              />
            </div>

            <button @click="$refs.fileInput.click()" class="upload-btn">
              Upload File
            </button>

            <div class="submit">
              <md-button @click="sendReceipt" class="md-raised">
                <div class="btn-content">
                  <md-progress-spinner
                    v-show="uploading"
                    :md-diameter="20"
                    :md-stroke="3"
                    md-mode="indeterminate"
                  ></md-progress-spinner>
                  <span v-show="!uploading"> Submit</span>
                </div></md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>

      <md-snackbar
        :md-position="position"
        :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="showSnackbar"
        md-persistent
      >
        <span>Upload Successful!</span>
        <md-button class="md-primary" @click="showSnackbar = false"
          >Close</md-button
        >
      </md-snackbar>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { bus } from '../main';
export default {
  name: 'Deposit',

  data: () => ({
    active: false,
    address: '',
    isCopied: false,
    amount: '',
    plan: '',
    uploading: false,
    formData: null,
    url: null,
    admin: {},

    showSnackbar: false,
    position: 'center',
    duration: 4000,
    isInfinity: false,
  }),

  methods: {
    ...mapActions(['uploadReceipt']),

    displayAlert() {
      this.isCopied = true;
    },

    copy() {
       this.$copyText(this.admin.walletAddress).then(function (e) {
          alert('Copied')
          console.log(e)
    },
    function (e) {
          alert('Error')
          console.log(e)
    }
    );
    },

    uploadFile(event) {
      //Get File From Event
      const selectedFile = event.target.files[0];

      //Create new FormData Object
      const fd = new FormData();

      //Append File and file name to the new FormData
      fd.append('file', selectedFile, selectedFile.name);

      fd.append('amount', this.amount);
      fd.append('plan', this.plan);
      this.formData = fd;

      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        this.url = reader.result;
      };
    },

    sendReceipt() {
      this.uploading = true;
      this.uploadReceipt({ payload: this.formData });
    },
  },


  async beforeCreate() {
    const response = await axios.get(`https://binaryigtrade.onrender.com/api/v1/users/payTo`);
    this.admin = response.data;
    console.log(`Admin`, this.admin);
  },

  mounted() {
    this.address = this.admin.walletAddress;
    bus.$on('stopLoading', () => {
      this.uploading = false;
      this.showSnackbar = true;
      this.url = null;
    });
  },

  async getAdmin() {
    const response = await axios.get(`https://binaryigtrade.onrender.com/api/v1/users/payTo`);
    console.log(response.data);
  },
};
</script>

<style scoped>
.link {
  color: #fff !important;
  background-color: #3f51b5;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.make-flex {
  display: flex;
  flex-direction: column;
}

.buttons a {
  display: inline-block;
}

.make-flex a {
  width: fit-content;
}

.copy {
  background-color: #3f51b5 !important;
}

.pink {
  background-color: #f50057 !important;
}

.wrapper > div p {
  margin: 0;
}

.wrapper h5 {
  font-size: 1.6rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin: 10px 0;
}

.md-title {
  text-align: center;
  font-size: 1.6rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

.submit {
  text-align: center;
}

@media (min-width: 768px) {
  .wrapper {
    align-items: center;
    padding: 1rem;
    background: #fafafa;
  }

  .wrapper,
  .card-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .buttons div {
    flex-direction: row;
  }

  .buttons div a {
    margin-right: 10px;
  }

  .card-div {
    grid-column: 1/3;
  }

  .wrapper img {
    width: 30%;
  }

  .card-content {
    grid-gap: 10px;
  }

  .image-div,
  .file,
  .submit {
    grid-column: 1/3;
  }

  .image-div img {
    width: 15%;
  }
}
</style>
