<template>
  <div>
    <footer>
      <div class="layout">
        <div>
          <h3>QUICK LINKS</h3>
          <div class="links">
            <router-link to="/">Home</router-link>
            <router-link to="/contact">Contact</router-link>
            <router-link to="/plans">Plans</router-link>
            <router-link to="/privacy">Privacy Policy</router-link>
          </div>
        </div>

        <div class="contact">
          <h3>CONTACT</h3>
          <div>
            <md-icon class="footer-icon">mail_outline</md-icon
            ><span>Helpdesk.binaryiqfxtrade@gmail.com</span>
          </div>
        </div>

        <div>
          <h3>FIRST IN CRYPTOCURRENCY</h3>
          <p>
            The Binary IQ FX Trade is one of the leading platforms in the United
            States offering binary options, Forex and spreads. Regulated by the
            CFTC and based in New York. It is also regulated by the IFSC of
            Belize, as well as the Cyprus Securities and Exchange Commission.
          </p>
        </div>

        <div class="laws">
          <h3>COPYRIGHT LAWS</h3>
          <p>
            All materials and services provided on this site are subject to
            copyright and belong to “BINARY IQ FX TRADE(B-I-F-T)”. Any use of
            materials of this website must be approved by an official
            representative of “B-I-F-T” or in line with the regulations under the
            "Privacy Policy, and contain a link to the original resource.
            Third-party companies do not have the right to use materials of this
            website as well as any distorted writing of “BINARY IQ FX TRADE”. In
            case of violation, they will be prosecuted in accordance with
            legislation of the intellectual property protection.
          </p>
        </div>
      </div>

      <div class="copyright">
        <p>Copyright © 2006-{{new Date().getFullYear()}} Binary IQ Trade All rights reserved. {{new Date().getFullYear()}}.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
footer {
  color: white;
  padding: 5vw;
  flex-grow: 1;
  background: rgb(8, 19, 41);
  margin-top: 2.5rem;
}

footer h3 {
  font-family: initial;
}

.footer-icon {
  color: white !important;
}

.links {
  display: grid;
}

.links a {
  color: #fff !important;
}

.copyright {
  text-align: center;
}

@media (min-width: 768px) {
  .layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .laws {
    grid-column: 1/4;
  }

  .copyright {
    padding: 15px;
    background: rgb(18, 29, 51);
  }

  footer {
    padding-bottom: 0px;
  }
}
</style>
