<template>
  <div>
    <div class="spinner" v-if="loading">
      <md-progress-spinner
        :md-diameter="30"
        :md-stroke="3"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div v-if="!loading" class="receipts">
      <md-card v-for="receipt in receipts" :key="receipt._id">
        <md-card-header>
          <md-avatar class="md-avatar-icon">{{
            receipt.user.name.split('')[0]
          }}</md-avatar>

          <div class="md-title">{{ receipt.user.name }}</div>
          <div class="md-subhead">Payment Receipt</div>
        </md-card-header>

        <md-card-media>
          <img :src="receipt.image" alt="photo" />
        </md-card-media>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { bus } from '../main';

export default {
  name: 'Receipts',

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapGetters(['receipts']),
  },

  methods: {
    ...mapActions(['getAllReceipts']),
  },

  created() {
    this.loading = true;
    this.getAllReceipts();
    bus.$on('stopLoading', () => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.spinner {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
</style>
