<template>
  <div>
    <md-field>
      <label>Search User</label>
      <md-input v-model="search" @keyup="filter"></md-input>
    </md-field>

    <md-list class="md-triple-line">
      <md-list-item v-for="user in copiedUsers" :key="user._id">
        <md-avatar class="md-avatar-icon">{{
          user.name.split('')[0]
        }}</md-avatar>

        <div class="md-list-item-text">
          <span>{{ user.name }}</span>
          <span>{{ user.email }}</span>
          <p>
            Deposit: <b>{{ user.totalDeposit }}</b>
          </p>

          <p>
            Balance: <b>{{ user.walletBalance }}</b>
          </p>

          <p>
            Profit: <b>{{ user.totalProfit }}</b>
          </p>
        </div>

        <md-button @click="setUser(user)" class="md-icon-button md-list-action">
          <!-- <md-icon class="md-primary">star</md-icon> -->
          Edit
        </md-button>
      </md-list-item>

      <!-- <md-divider class="md-inset"></md-divider> -->
    </md-list>

    <div>
      <md-dialog class="dialog" :md-active.sync="showDialog">
        <div class="dialog-wrapper">
          <md-dialog-title>Edit User Details</md-dialog-title>

          <md-field>
            <label>Deposit</label>
            <md-input type="number" v-model="payload.totalDeposit"></md-input>
          </md-field>

          <md-field>
            <label>Balance</label>
            <md-input type="number" v-model="payload.walletBalance"></md-input>
          </md-field>

          <md-field>
            <label>Profits</label>
            <md-input type="number" v-model="payload.totalProfit"></md-input>
          </md-field>

          <md-button @click="add" class="md-raised md-primary">ADD</md-button>
          <md-button @click="subtract" class="md-raised md-accent"
            >SUBTRACT</md-button
          >
          <md-button @click="showDialog = false" class="md-raised "
            >CANCEL</md-button
          >
        </div>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Users',

  data: () => ({
    showDialog: false,
    subject: '',
    message: '',
    search: '',
    copiedUsers: '',
    user: null,
    payload: {
      totalProfit: '',
      totalDeposit: '',
      walletBalance: '',
    },
  }),

  methods: {
    ...mapActions([
      'getUsers',
      'addToWallet',
      'removeFromWallet',
      'updateUser',
    ]),

    async subtract() {
      this.active = false;

      this.loading = true;

      await this.removeFromWallet({ id: this.user.id, payload: this.payload });
      await this.initUsers();

      this.loading = false;
      this.showDialog = false;
    },

    async add() {
      this.active = false;

      this.loading = true;

      await this.addToWallet({
        id: this.user.id,
        payload: this.payload,
      });
      await this.initUsers();
      this.loading = false;
      this.showDialog = false;
    },

    async initUsers() {
      await this.getUsers();
      this.loading = false;

      this.copiedUsers = this.users;
    },

    filter() {
      if (this.search !== '') {
        this.copiedUsers = this.copiedUsers.filter((user) => {
          return user.name.toLowerCase().includes(this.search.toLowerCase());
        });
      } else {
        this.copiedUsers = this.users;
      }
    },

    setUser(user) {
      this.showDialog = true;
      this.user = user;
    },
  },

  computed: {
    ...mapGetters(['users']),
  },

  async created() {
    await this.initUsers();
  },
};
</script>

<style scoped>
.cancel {
  display: flex;
  justify-content: center;
}

.cancel i {
  margin: 0;
}

.dialog {
  padding: 1rem;
}

.dialog-wrapper {
  padding: 1rem;
}
</style>
