<template>
  <div>
    <div
      id="slide-me-up"
      class="tradingview-widget-container"
      style="width: 100%; height: 46px;"
    >
      <iframe
        scrolling="no"
        allowtransparency="true"
        frameborder="0"
        src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22OANDA%3ASPX500USD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22OANDA%3ANAS100USD%22%2C%22title%22%3A%22Nasdaq%20100%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22BTC%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22ETH%2FUSD%22%7D%5D%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22binaryiqtrade.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%7D"
        style="box-sizing: border-box; height: 46px; width: 100%;"
      ></iframe>
    </div>

    <header>
      <h1>
        The World's Most Popular Way to Buy, Hold, Invest, Mine & trade Crypto
      </h1>

      <p>
        Trusted by 53M Wallets - with Over $620 Billion in Transactions - Since
        2013
      </p>

      <router-link to="/register" class=" link">GET STARTED</router-link>
    </header>

    <section id="easy">
      <div class="wrapper">
        <h1 class="heading">Easiest & Most Powerful Assets</h1>

        <div class="layout">
          <div class="showcase">
            <video
              autoplay
              loop
              src="../../public/images/crypto.8a40d45a.mp4"
            ></video>
          </div>

          <div class="content">
            <h4>
              Explore an ever-expanding variety of cryptocurrencies, and buy and
              invest the underlying asset on Binary IQ Trade Cryptocurrency
              Investment Platform. Build your crypto-based portfolio and enjoy
              benefits not offered by most exchanges, such as near-immediate
              execution of market orders.
            </h4>

            <router-link to="/register" class=" link"
              >CREATE AN ACCOUNT</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <section id="numbers">
      <h1 class="heading">Our Numbers</h1>

      <div class="numbers">
        <div class="num">
          <h3>2.92+</h3>
          <h4>TRILLION USD IN TRADING VOLUME</h4>
        </div>

        <div class="num">
          <h3>6.3+</h3>
          <h4>BILLION ORDERS EXECUTED</h4>
        </div>

        <div class="num">
          <h3>363.5+</h3>
          <h4>MILLION TRADERS SERVED</h4>
        </div>

        <div class="num">
          <h3>600+</h3>
          <h4>INSTRUMENTS OFFERED</h4>
        </div>
      </div>
    </section>

    <section id="why">
      <h1 class="heading">Why Binary IQ Trade</h1>

      <p>
        Discover why millions of users from over 140 countries choose to invest
        with Binary IQ Trade
      </p>

      <div class="cards">
        <div class="card">
          <md-card>
            <md-card-header>
              <div class="icon-div">
                <md-icon class="icon">security</md-icon>
              </div>
              <h2 class="heading">Privacy & Security</h2>
            </md-card-header>

            <md-card-content>
              <p>
                Our commitment to safety assures integrity, so you can
                concentrate on what’s important - investing in the markets.
              </p>
            </md-card-content>
          </md-card>
        </div>

        <div class="card">
          <md-card>
            <md-card-header>
              <div class="icon-div">
                <md-icon class="icon">security</md-icon>
              </div>
              <h2 class="heading">Ease of Access</h2>
            </md-card-header>

            <md-card-content>
              <p>
                Our platform is customized to make it fit better to your needs
                and exploration.
              </p>
            </md-card-content>
          </md-card>
        </div>

        <div class="card">
          <md-card>
            <md-card-header>
              <div class="icon-div">
                <md-icon class="icon">fast_forward</md-icon>
              </div>
              <h2 class="heading">24/7 Customer Support</h2>
            </md-card-header>

            <md-card-content>
              <p>
                Our team of professionals and Investment experts are always here
                to support you.
              </p>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <div class="layout">
        <div class="content">
          <h1 class="heading">
            The Market is in our DNA
          </h1>
          <h4>
            Our award-winning, intuitive platforms are equipped with a suite of
            powerful tools to support your investments and ensure maximization
            of your funds and potential profits. Our Platforms supports all
            devices making it easily available for everyone everywhere to have a
            share in the Cryptocurrency industry.
          </h4>
        </div>

        <div class="showcase">
          <img
            src="../../public/images/devices-main-desktop-light-small.bb908040.webp"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </section>

    <section id="plans">
      <div class="plan-head">
        <h1 class="heading">
          Our Plan
        </h1>

        <p>
          Our aim is to reduce our negative social, economic and environmental
          footprints while enhancing the overall value we create as a leading
          brand
        </p>
      </div>

      <div class="plans">
        <md-card class="plan">
          <md-card-header class="starter">
            <span>Starter</span>
          </md-card-header>

          <md-card-content>
            <div class="details">
              <div class="list-item">
                <h2>$1000</h2>
              </div>
              <md-divider></md-divider>

              <div class="list-div">
                <span>30% Investment</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Personal Manager</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Email Support</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>24/7 support</span>
              </div>

              <md-divider></md-divider>
            </div>
          </md-card-content>

          <router-link to="/register" class=" border">START NOW</router-link>
        </md-card>

        <md-card class="plan">
          <md-card-header class="starter">
            <span>Basic</span>
            <p>Most popular</p>
          </md-card-header>

          <md-card-content>
            <div class="details">
              <div class="list-item">
                <h2>$10,000</h2>
              </div>
              <md-divider></md-divider>

              <div class="list-div">
                <span>30% Investment</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Personal Manager</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Email Support</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>24/7 support</span>
              </div>

              <md-divider></md-divider>
            </div>
          </md-card-content>

          <router-link to="/register" class="background">START NOW</router-link>
        </md-card>

        <md-card class="plan">
          <md-card-header class="starter ">
            <div class="star">
              <span>Pro</span> <md-icon class="md-icon">star_border</md-icon>
            </div>
          </md-card-header>

          <md-card-content>
            <div class="details">
              <div class="list-item">
                <h2>$20,000</h2>
              </div>
              <md-divider></md-divider>

              <div class="list-div">
                <span>30% Investment</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Personal Manager</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Email Support</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>24/7 support</span>
              </div>

              <md-divider></md-divider>
            </div>
          </md-card-content>

          <router-link to="/register" class="background">START NOW</router-link>
        </md-card>

        <md-card class="plan">
          <md-card-header class="starter">
            <span>Executive</span>
          </md-card-header>

          <md-card-content>
            <div class="details">
              <div class="list-item">
                <h2>$50,000</h2>
              </div>
              <md-divider></md-divider>

              <div class="list-div">
                <span>75% Investment</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Executive Manager</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>Phone & email Support</span>
              </div>

              <md-divider></md-divider>

              <div class="list-div">
                <span>24/7 support</span>
              </div>

              <md-divider></md-divider>
            </div>
          </md-card-content>

          <router-link to="" class=" border">START NOW</router-link>
        </md-card>
      </div>
    </section>

    <section id="tech">
      <h1 class="heading">
        Access to all Crypto Tech
      </h1>

      <p>
        Have complete access and control over your invested assets and can swap
        assets feasibly with relative ease
      </p>

      <div class="chart">
        <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
          <div
            id="id-crypto-widget"
            class="tradingview-widget-container undefined"
            style="width: 100%; height: 490px;"
          >
            <iframe
              id="script-made-id"
              allowtransparency="true"
              frameborder="0"
              src="https://www.tradingview-widget.com/embed-widget/crypto-mkt-screener/?locale=en#%7B%22frameElementId%22%3A%22script-made-id%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22490%22%2C%22defaultColumn%22%3A%22overview%22%2C%22screener_type%22%3A%22crypto_mkt%22%2C%22displayCurrency%22%3A%22USD%22%2C%22colorTheme%22%3A%22light%22%2C%22market%22%3A%22crypto%22%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22binaryiqtrade.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22cryptomktscreener%22%7D"
              style="box-sizing: border-box; height: 490px; width: 100%;"
            ></iframe>
          </div>
        </div>
      </div>
    </section>

    <section id="faq">
      <h1 class="heading">Frequently Asked questions</h1>

      <div class="row">
        <div class="col">
          <div class="tabs">
            <div class="tab">
              <input type="checkbox" id="chck1" />
              <label class="tab-label" for="chck1">What is Bitcoin?</label>
              <div class="tab-content">
                Bitcoin is a type of digital currency in which a record of transactions is maintained
                and new units of currency are generated by the computational solution of mathematical problems,
                and which operates independently of a central bank.
                "Bitcoin has become a hot comodity among speculators"
                Bitcoins can be used for many online transactions between individuals.
              </div>
            </div>
            <div class="tab">
              <input type="checkbox" id="chck2" />
              <label class="tab-label" for="chck2"
                >What is Bitcoin mining and Bitcoin trading?</label
              >
              <div class="tab-content">
                Bitcoin mining is the process of verifying new transactions to the bitcoin digital currency system,
                as well as the process by which new Bitcoin enter into circulation.
                Trading bitcoin works by enabling you to take a speculative position on bitcoin's price movements
                with financial derivatives such as CFDs. These will enable you to go long and speculate on the price rising,
                as well as short and speculate on the price falling.
              </div>
            </div>

            <div class="tab">
              <input type="checkbox" id="chck3" />
              <label class="tab-label" for="chck3">How To Invest?</label>
              <div class="tab-content">
                Invest in 3 simple steps:
                Sign up with us here to open a trading account.
                Purchase Bitcoins from any trusted Cryptocurrency merchant app or website of your choice. 
                Send Bitcoins worth your intended startup capital from your bitcoin mercahnat app by sending to the unique wallet address dispalyed on your personal dashboard.
                <router-link class="link" to="/register">GET STARTED</router-link>
              </div>
            </div>

            <div class="tab">
              <input type="checkbox" id="chck4" />
              <label class="tab-label" for="chck4"
                >Are we Registered Legally?</label
              >
              <div class="tab-content">
                Blockchain is legally registered to function internationally
                except countries that ban Crypto currencies. If you fall within
                that category, then you can access support to invest in another
                asset within our catalogue.
              </div>
            </div>

            <div class="tab">
              <input type="checkbox" id="chck5" />
              <label class="tab-label" for="chck5">Is there any risk?</label>
              <div class="tab-content">
                In business there are always risk but we have minimized that to
                the lowest by automatically creating an insurance account for
                our customers to curb major losses in rare cases. So be
                confident and invest to your heart's content
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 class="heading steping">Steps</h1>

      <div class="steps">
        <div class="step">
          <md-avatar class="md-avatar-icon md-small first">1</md-avatar>
          <p class="darken">
            Fill out the form and select a password to register
          </p>
        </div>

        <div class="step">
          <md-avatar class="md-avatar-icon md-small">2</md-avatar>
          <p>Fund your account from a variety of payment methods</p>
        </div>

        <div class="step">
          <md-avatar class="md-avatar-icon md-small">3</md-avatar>
          <p>Start mining & with no delay. Thousands of markets await you</p>
        </div>

        <md-steppers class="md-stepper">
          <md-step id="first" md-label="First Step">
            <p>Fill out the form and select a password to reggister for a trading account.</p>
          </md-step>

          <md-step id="second" md-label="Second Step">
            <p>Fund your trading account by purchasing Bitcoin from any trusted Cryptocurrency merchant app of your choice,
              login back into your trading account here and click on "DEPOSIT"</p>
          </md-step>

          <md-step id="third" md-label="Third Step">
            <p>
              Start mining & with no delay. Thousands of markets await you
            </p>
          </md-step>
        </md-steppers>
      </div>
    </section>

    <section id="awards">
      <h1 class="heading">
        <md-icon class="eco">eco</md-icon> 18 International Awards
        <md-icon class="eco">eco</md-icon>
      </h1>

      <div class="cards">
        <md-card class="card">
          <md-card-header>
            <h3 class="card-heading">Best Crypto Broker</h3>
          </md-card-header>

          <md-card-content>
            <h4>2017</h4>
            <p>
              The Forex Expo Dubai.
            </p>
          </md-card-content>
        </md-card>

        <md-card class="card">
          <md-card-header>
            <h3 class="card-heading">Most Innovative Crypto Investments</h3>
          </md-card-header>

          <md-card-content>
            <h4>2018</h4>
            <p>
              World Finance Markets Awards.
            </p>
          </md-card-content>
        </md-card>

        <md-card class="card">
          <md-card-header>
            <h3 class="card-heading">Best APAC Region Provider</h3>
          </md-card-header>

          <md-card-content>
            <h4>2017</h4>
            <p>
              ADVFN international Financial Awards.
            </p>
          </md-card-content>
        </md-card>

        <md-card class="card">
          <md-card-header>
            <h3 class="card-heading">
              Most Transparent Cryptocurrency Investment Platform
            </h3>
          </md-card-header>

          <md-card-content>
            <h4>2016</h4>
            <p>
              Crypto-Awards.
            </p>
          </md-card-content>
        </md-card>

        <md-card class="card">
          <md-card-header>
            <h3 class="card-heading">
              Best Crypto Service Provider
            </h3>
          </md-card-header>

          <md-card-content>
            <h4>2017</h4>
            <p>
              FXWorld
            </p>
          </md-card-content>
        </md-card>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data() {
    return {
      expandNews: false,
      expandSingle: false,
    };
  },
};
</script>

<style scoped>
.content {
  padding: 15px;
}

header h1,
header p {
  color: #fff;

  margin: 10px auto;
}

header h1 {
  font-family: 'Fira Code', monospace;
  font-size: 2.25rem;
  line-height: 1.167;
  letter-spacing: 0em;
  font-weight: 400;
}

header p {
  /* font-weight: 500; */
  font-family: initial;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  font-size: 1rem;
  max-width: 80%;
}

.coins {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coin {
  color: white;
  padding: 8px;
  background: rgb(18, 29, 51);
  text-align: center;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin: 10px 0;
  width: auto;
  width: fit-content;
  padding: 10px 6rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.coin span {
  font-size: 1rem;
  font-family: initial;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.coins .icon {
  font-size: 24px;
  margin-right: 5px;
}

#numbers {
  background: rgb(8, 19, 41);
  padding: 10vw;
}

.numbers {
  padding: 15px;
}

#numbers h1 {
  color: #fff;
}

.num {
  color: rgb(18, 29, 51);
  margin: 10px;
  padding: 25px 15px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 18px;
}

.num h3 {
  font-size: 2.25rem;
  font-family: 'Fira Code';
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.num h4 {
  font-size: 1.5rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  padding: 0;
  box-sizing: border-box;
  width: 85%;
  margin: 0 auto;
}

.cards .icon {
  color: rgb(249, 168, 37);
  font-size: 40px !important;
}

.cards h2 {
  font-size: 1.5rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  color: black;
  margin: 10px;
}

.cards p {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-family: initial;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  text-align: start;
}

.md-card-header {
  padding-top: 26px;
  padding-bottom: 0;
}

.card {
  font-size: 1rem;
  font-family: FiraCode;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 1rem 10px;
}

.md-app-content .md-card {
  margin: 1rem 10px;
}
#why {
  color: black;
}

#why .content {
  font-size: 1.5rem;
  font-family: Arvo;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

#plans {
  background: rgb(18, 29, 51);
  padding-bottom: 10vh;
  color: #fff;
}

.plans {
  text-align: center;
}

.border {
  border: 1px solid rgba(63, 81, 181, 0.5);
  color: rgba(63, 81, 181, 0.5);
}

.background {
  background-color: #3f51b5;
  border: none;
  color: #fff !important;
}

.starter {
  padding: 16px;
  font-size: 1.5rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  background-color: #eeeeee;
}

.details h2 {
  font-size: 2.125rem;
  font-family: Roboto;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  margin: 16px 0 16px 0;
}

.list-div {
  padding: 10px 0;
}

.list-div span {
  font-size: 1.2em;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
}
.plan {
  padding-bottom: 10px;
  margin-bottom: 15px !important;
}

.plan a {
  display: block;
  padding: 7px 15px;
  width: 95%;
  margin: 0 auto;
}

.md-icon {
  margin: 0;
  float: right;
}

.starter p {
  font-size: 1rem;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 4px;
}

.plan-head {
  padding: 64px 0px 48px;
}

.plan-head h1 {
  margin: 0;
  margin-bottom: 0.35em;
}

.showcase img {
  width: 95%;
  margin: 0 auto;
}

#tech h1 {
  margin-bottom: 10px;
}

#tech p {
  font-size: 1rem;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  max-width: 85%;
  margin: 10px auto;
  color: black;
}

.chart {
  padding: 20px;
}

h1 {
  margin: 0;
  line-height: 2;
  text-align: center;
}

h2 {
  margin: 0 0 0.5em;
  font-weight: normal;
}

input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.row {
  display: flex;
  background: white;
}

/* Accordion styles */
.tabs {
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  color: black;
  text-align: start;
  cursor: pointer;

  /* Icon */
}

.tab-label::after {
  content: '❯';
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.1s;
  text-align: start;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: #1a252f;
}

input:checked + .tab-label::after {
  transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

#faq {
  padding: 5vw 10vw;
  color: white;
  width: 100%;
  padding: 5vw 10vw;
  background: rgb(18, 29, 51);
}

#faq a {
  padding: 6px 16px;
}

#faq h1 {
  margin-bottom: 10vh;
  font-size: 2.25rem;
  font-family: 'Fira Code';
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  text-align: start;
}

.steps {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
  padding: 24px;
}

.first {
  background: #3f51b5 !important;
}

.darken {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

#awards h1 {
  font-size: 2.25rem;
  font-family: 'Fira Code';
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  width: 95%;
  align-items: center;
}

.eco {
  font-size: 36px !important;
  color: green !important;
  margin: 0 15px;
}

.card-heading {
  font-family: initial;
  margin: 5px 0;
}

#awards h4 {
  font-size: 1rem;
  font-family: 'FiraCode';
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #3f51b5;
  margin: 5px 0;
}

#awards p {
  font-size: 1rem;
  font-family: 'FiraCode';
  font-weight: lighter;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  text-align: center;
  color: black;
  margin: 5px 0;
}

.cards {
  padding: 2.5rem;
}

#award .card {
  margin-bottom: 1rem;
}

.icon-div {
  display: inline-flex;
}

.md-stepper {
  display: none;
}

@media (min-width: 768px) {
  header h1 {
    width: 75%;
  }

  .coins {
    flex-direction: row;
    justify-content: space-around;

    width: 85%;
    padding: 15px;
    margin: 0 auto;
  }

  .layout,
  .numbers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .wrapper {
    padding: 2.5rem;
  }

  #easy {
    background-color: white;
  }

  .numbers,
  .plans {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    grid-gap: 15px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }

  .plans {
    padding: 2.5rem;
  }

  #plans p {
    width: 60%;
    margin: 0 auto;
  }

  .steping {
    margin: 2rem 0;
  }

  .step {
    display: none;
  }

  .md-steppers-navigation {
    box-shadow: none;
  }

  #awards {
    background-color: white;
    padding: 3rem 3.5rem;
  }

  #awards .cards {
    grid-template-columns: repeat(5, 1fr);
    padding: 0 5.5rem;
  }

  .md-stepper {
    display: block;
  }
}
</style>
