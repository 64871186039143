<template>
  <div class="page-container">
    <md-app>
      <md-app-toolbar class="md-primary toolbar" md-elevation="0">
        <md-button
          class="md-icon-button"
          @click="toggleMenu"
          v-if="!menuVisible"
        >
          <md-icon>menu</md-icon>
        </md-button>
        <div class="dash-div">
          <h1 class="dash-header">Dashboard</h1>
          <md-menu md-direction="bottom-start">
            <md-button md-menu-trigger
              ><md-icon>account_circle</md-icon>
              <span>{{ currentUser.name }}</span></md-button
            >

            <md-menu-content>
              <md-menu-item
                ><router-link to="/account/settings"
                  >Profile</router-link
                ></md-menu-item
              >
              <md-menu-item
                ><router-link to="/account/transaction"
                  >Transactions</router-link
                ></md-menu-item
              >
              <md-menu-item @click="logout">Logout</md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="menuVisible" md-persistent="mini">
        <md-toolbar class="md-transparent" md-elevation="0">
          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>

        <md-list class="md-list">
          <md-list-item>
            <router-link to="/account/dashboard">
              <md-icon class="icon">dashboard</md-icon>
              <span class="md-list-item-text">Dashboard</span>
            </router-link>
          </md-list-item>

          <md-list-item>
            <router-link to="/account/deposit">
              <md-icon class="icon">account_balance</md-icon>
              <span class="md-list-item-text">Deposit</span>
            </router-link>
          </md-list-item>

          <md-list-item>
            <router-link to="/account/withdrawal">
              <md-icon class="icon">monetization_on</md-icon>
              <span class="md-list-item-text">Withdrwal</span>
            </router-link>
          </md-list-item>

          <md-list-item>
            <router-link to="/account/referral">
              <md-icon class="icon">group</md-icon>
              <span class="md-list-item-text">Referral</span>
            </router-link>
          </md-list-item>

          <md-list-item>
            <router-link to="/account/transaction">
              <md-icon class="icon">equalizer</md-icon>
              <span class="md-list-item-text">Transaction</span>
            </router-link>
          </md-list-item>

          <md-list-item>
            <router-link to="/account/support">
              <md-icon class="icon">layers</md-icon>
              <span class="md-list-item-text">Support</span>
            </router-link>
          </md-list-item>

          <md-list-item>
            <router-link to="/account/settings">
              <md-icon class="icon">settings</md-icon>
              <span class="md-list-item-text">Settings</span>
            </router-link>
          </md-list-item>

          <md-list-item v-if="currentUser.role === 'admin'">
            <router-link to="/account/users">
              <md-icon class="icon">supervised_user_circle</md-icon>
              <span class="md-list-item-text">Users</span>
            </router-link>
          </md-list-item>

          <md-list-item v-if="currentUser.role === 'admin'">
            <router-link to="/account/receipts">
              <md-icon class="icon">receipt</md-icon>
              <span class="md-list-item-text">Receipts</span>
            </router-link>
          </md-list-item>

          <md-list-item @click="logout">
            <md-icon class="icon">exit_to_app</md-icon>
            <span class="md-list-item-text">Sign Out</span>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <md-app-content class="content">
        <router-view />
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Account',
  data: () => ({
    menuVisible: false,
  }),
  methods: {
    ...mapActions(['logout']),

    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },

  computed: {
    ...mapGetters(['currentUser']),
  },
};
</script>

<style scoped>
.md-app {
  min-height: 350px;
  border: 1px solid rgba(#000, 0.12);
  height: 100vh;
}

.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}

.md-list a {
  width: 100%;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 1rem;
}

.md-list .icon {
  color: #f50057;
}

.md-list span {
  margin-left: 1rem;
}

.toolbar {
  background-color: #3f51b5 !important;
}

.dash-header {
  font-size: 1rem;
  font-family: initial;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.dash-div {
  display: flex;
  justify-content: space-between;
  width: 85%;
  align-items: center;
}

.dash-div span {
  text-transform: capitalize;
}

.content {
  background: #fafafa;
}

@media (max-width: 400px) {
  .dash-div {
    width: 85%;
  }
}
</style>
