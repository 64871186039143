<template>
  <div class="wrapper">
    <div class="pic"></div>
    <div class="form">
      <form class="search-input">
        <div v-show="errorMsg" class="error-div">
          {{ errorMsg }}
        </div>
        <md-avatar class="md-avatar-icon avatar"
          ><md-icon>lock</md-icon></md-avatar
        >

        <h1>Sign In</h1>
        <div class="input-container">
          <input v-model="payload.email" class="input" type="text" required />
          <span class="label">Email Address *</span>
        </div>
        <div class="input-container">
          <input
            v-model="payload.password"
            class="input"
            type="password"
            required
          />
          <span class="label">Password *</span>
        </div>

        <md-checkbox v-model="boolean" class="md-primary"
          >Remember Me</md-checkbox
        >
        <md-button @click="sendLogin" class="md-raised md-primary sub-btn">
          <md-progress-spinner
            :md-diameter="20"
            v-if="loading"
            :md-stroke="3"
            md-mode="indeterminate"
          ></md-progress-spinner
          ><span v-if="!loading">SIGN IN</span></md-button
        >

        <div class="reg-link">
          <router-link to="/register"
            >Forgot Password?Dont have an account?Register</router-link
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { bus } from '../main';

export default {
  name: 'Login',

  data: () => ({
    boolean: true,
    loading: false,
    payload: {
      email: '',
      password: '',
    },
  }),

  methods: {
    ...mapActions(['login']),

    sendLogin() {
      this.loading = true;
      this.login(this.payload);
    },
  },

  mounted() {
    bus.$on('stopLoading', () => {
      console.log('stopLoading');
      this.loading = false;
    });
  },

  computed: {
    ...mapGetters(['errorMsg']),
  },
};
</script>

<style scoped>
.error-div {
  text-align: center;
  color: #f70c0c;
  padding: 1rem;
}

.search-input {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  position: absolute;
  left: 50%;
  bottom: 45%;
  transform: translate(-50%, 0);

  width: 90%;
}

.input-container {
  display: flex;
  position: relative;
  height: 75px;
  width: 100%;
  padding-left: 5px;
  margin-right: 10px;
  align-items: center;
}

.input {
  height: 55px;
  width: inherit;
  font-size: 14px;
  caret-color: #990000;
  padding-left: 10px;
  border-radius: 4px;
  border-style: solid;
  border-color: #c2c2c2;
  border-width: 0.5px;
  float: left;

  -webkit-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.input:hover {
  border-color: black;
}

.input:valid + .label {
  top: 2px;
  left: 10px;
  font-size: 12px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.input:focus {
  padding-left: 9px;
  border-width: 2px;
  border-color: #3f51b5;
  outline: none;
}

.input:focus + .label {
  top: 2px;
  left: 10px;
  font-size: 12px;
  color: #3f51b5;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.search {
  float: right;
}

.label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 27px;
  font-size: 14px;
  color: #666666;

  -webkit-transition: all 0.5s; /* For Safari 3.0 to 6.0 */
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s; /* For modern browsers */
}

.wrapper {
  height: 100vh;
  background: #fff;
}

form h1 {
  font-size: 1rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  text-align: center;
}

.avatar {
  background: #f50057 !important;
}

.sub-btn {
  background: #3f51b5 !important;
}

.reg-link a {
  color: #3f51b5;
}

@media (min-width: 768px) {
  .wrapper {
    display: grid;
    grid-template-columns: 8fr 6fr;
    grid-gap: 2px;
  }

  .form {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    padding: 5rem 10px;
  }

  .search-input {
    position: inherit;
    transform: none;
    margin: 0 auto;
    bottom: 50%;
  }

  .pic {
    background: url('../../public/images/p2pblue.223a3c21.svg') no-repeat;
    background-size: cover;
    background-position: center;

    background-color: #fafafa;
  }
}
</style>
