<template>
  <div class="container">
    <h1>Support</h1>

    <md-avatar class="md-avatar-icon">
      <md-icon>layers</md-icon>
    </md-avatar>

    <p>Need help? we're here for you.
      Click on the blue chat button at the buttom right of this page and send us a message.
      We'll get back to you as soon as possible</p>

    <md-snackbar
      :md-position="position"
      :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="showSnackbar"
      md-persistent
    >
      <span>Message Successfully!</span>
      <md-button class="md-primary" @click="showSnackbar = false"
        >Close</md-button
      >
    </md-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Support',

  data: () => ({
    boolean: false,
    subject: '',
    message: '',
    loading: false,
    showSnackbar: false,
    position: 'center',
    duration: 4000,
    isInfinity: false,
  }),

  methods: {
    sendDetails() {
      this.loading = true;
      this.subject = '';
      this.message = '';

      setTimeout(() => {
        this.loading = false;
        this.showSnackbar = true;
      }, 5000);
    },
  },
};
</script>

<style scoped>
.search-input {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}

.input-container {
  display: flex;
  position: relative;
  height: 75px;
  width: 100%;
  padding-left: 5px;
  margin-right: 10px;
  align-items: center;
}

.input {
  height: 55px;
  width: 100%;
  font-size: 14px;
  caret-color: #990000;
  padding-left: 10px;
  border-radius: 4px;
  border-style: solid;
  border-color: #c2c2c2;
  border-width: 0.5px;
  float: left;
  background: transparent;

  -webkit-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.input:hover {
  border-color: black;
}

.input:valid + .label {
  top: 2px;
  left: 10px;
  font-size: 12px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.input:focus {
  padding-left: 9px;
  border-width: 2px;
  border-color: #3f51b5;
  outline: none;
}

.input:focus + .label {
  top: 2px;
  left: 10px;
  font-size: 12px;
  color: #3f51b5;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.search {
  float: right;
}

.label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 27px;
  font-size: 14px;
  color: #666666;

  -webkit-transition: all 0.5s; /* For Safari 3.0 to 6.0 */
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s; /* For modern browsers */
}

h1 {
  font-size: 2.25rem;
  font-family: 'Fira Code';
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  display: inline;
}

.container {
  text-align: center;
  margin-top: 4.5rem;
}

h3 {
  font-size: 1.5rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

.md-avatar-icon {
  margin: 8px;
  display: inline-block;
  text-align: center;
  padding-top: 8px;
  background-color: blue !important;
}

.withdraw {
  background-color: #303f9f !important;
  display: inline-block;
  width: fit-content;
}

p {
  font-family: initial;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
</style>
