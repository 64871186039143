<template>
  <div>
    <div
      id="slide-me-up"
      class="tradingview-widget-container"
      style="width: 100%; height: 46px;"
    >
      <iframe
        scrolling="no"
        allowtransparency="true"
        frameborder="0"
        src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22OANDA%3ASPX500USD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22OANDA%3ANAS100USD%22%2C%22title%22%3A%22Nasdaq%20100%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22BTC%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22ETH%2FUSD%22%7D%5D%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22binaryiqtrade.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%7D"
        style="box-sizing: border-box; height: 46px; width: 100%;"
      ></iframe>
    </div>

    <header>
      <div class="showcase-div">
        <h1>Payment Policy</h1>
      </div>

      <div class="list">
        <ul class="md-list">
          <li class="list-item">
            <span class="number">1.1</span>
            <p>
              The company is financially responsible for the clients account
              balance in any particular moment.
            </p>
          </li>

          <li>
            <span class="number">1.2</span>
            <p>
              Company's financial responsibility starts with the first record
              about the customer's deposit and continues up to a full withdrawal
              of funds.
            </p>
          </li>

          <li>
            <span class="number">1.3</span>
            <p>
              The client has the right to demand from the Company any amount of
              funds which is available in his/her account at the time of the
              enquiry.
            </p>
          </li>

          <li>
            <span class="number">1.4</span>
            <p>
              The only official methods of deposits/withdrawals are the methods
              which appear on the company's official website. The client assumes
              all the risks related to the usage of these payment methods since
              the payment methods are not the company's partners and not the
              company's responsibility. The company is not responsible for any
              delay or cancelation of a transaction which was caused by the
              payment method. In case the client has any claims related to any
              of the payment methods, it is his/her responsibility to contact
              the support service of the particular payment method and to notify
              the company about those claims.
            </p>
          </li>

          <li>
            <span class="number">1.5</span>
            <p>
              The company does not assume any responsibility for the activity of
              any third party service providers which the customer may use in
              order to make a deposit/withdrawal. The company's financial
              responsibility for the client's funds starts when the funds have
              been loaded to the company's bank account or any other account
              related to the payment methods which appear on the company's
              website. In case any fraud is detected during or after a financial
              transaction, the company reserves the right to cancel such
              transaction and freeze the client's account. The Company's
              responsibility for the clients' funds ends when the funds are
              withdrawn from the company's bank account or any other account
              related to the company.
            </p>
          </li>

          <li>
            <span class="number">1.6</span>
            <p>
              In case of any technical mistakes related to financial
              transactions, the company reserves the right to cancel such
              transactions and their results.
            </p>
          </li>

          <li>
            <span class="number">1.7</span>
            <p>
              The client may have only one registered account on the company's
              website. In case the company detects any duplication of the
              customer's accounts, the company reserves the right to freeze the
              customer's accounts and funds without the right of withdrawal.
            </p>
          </li>

          <li>
            <span class="number">2</span>
            <div>
              <p>
                Client's registration
              </p>

              <p class="sub-list">
                Client's registration is based on two main steps:
              </p>

              <p class="sub-list">- Client's web registration.</p>

              <p>
                - Client's identity verification.
              </p>
            </div>
          </li>

          <li>
            <span class="number">2.1</span>
            <div>
              <p>
                In order to complete the first step the client needs to:
              </p>

              <p class="sub-list">
                - Provide the company with his/her real identity and contact
                details.
              </p>

              <p class="sub-list">
                - To accept the company's agreements and their appendices.
              </p>

              <p>
                In order to complete the second step the company needs to
                request and the client needs to provide
              </p>

              <p class="sub-list">
                - a scan or digital photo of his/her identification document.
                details.
              </p>

              <p class="sub-list">
                - full copy of all the pages of his/her ID documet with the
                photo and personal details.
              </p>

              <p>
                The company reserves the right to demand from the client any
                other documents, such as payment bills, bank confirmation, bank
                card scans or any other document that may be necessary during
                the identification process.
              </p>
            </div>
          </li>

          <li>
            <span class="number">2.3</span>
            <p>
              The identification process must be completed in 10 business days
              since the company`s request. In some cases the company may
              increase the identification period up to 30 working days.
            </p>
          </li>

          <li>
            <span class="number">3</span>
            <div>
              <p>
                Deposit process
              </p>

              <p>
                In order to make a deposit, the client shall to make an enquiry
                from his Personal Cabinet. In order to complete the enquiry, the
                client needs to choose any of the payment methods from the list,
                fill in all the necessary details and continue with the payment.
              </p>
            </div>
          </li>

          <li>
            <span class="number">3.1</span>

            <div>
              <p>
                The following currencies are available for deposit: USD
              </p>
              <p>
                Withdrawal request processing time depends on the payment method
                and may vary from one method to another. The company cannot
                regulate the processing time. In case of using electronic
                payment methods, the transaction time can vary from seconds to
                days. In case of using direct bank wire, the transaction time
                can be акщь 3 up to 45 business days.
              </p>
            </div>
          </li>

          <li>
            <span class="number">4</span>
            <div>
              <p>
                Taxes
              </p>

              <p>
                The company is not a tax agent and does not provide the clients'
                financial information to any third parties. This information can
                only be provided in case of an official demand from government
                agencies.
              </p>
            </div>
          </li>

          <li>
            <span class="number">5</span>
            <div>
              <p>
                Refund policy.
              </p>

              <p>
                At any time a Client can withdraw a part or all funds from
                his/her Account by sending the Company a Request for Withdrawal
                containing the Client’s order to withdraw money from the
                Client’s Account, which complies with the following terms:
              </p>

              <p class="sub-list">
                - the Company will execute the order for withdrawal from the
                Client’s trading account, which will be limited by the remaining
                balance of the Client’s Account at the time of order execution.
                If the amount withdrawn by the Client (including commissions and
                other expenses as per this Regulation) exceeds the balance of
                the Client's Account, the Company may reject the order after
                explaining the reason for the rejection.;
              </p>
            </div>
          </li>

          <li>
            <span class="number">5.1</span>
            <div>
              <p>
                - the Client's order to withdraw money from the Client's Account
                must comply with the requirements and restrictions set forth by
                current legislation and other provisions of the countries in the
                jurisdiction of which such transaction is made;
              </p>

              <p>
                - money from the Client's Account must be withdrawn to the same
                payment system with the same purse ID that was previously used
                by the Client to deposit funds to the Account. The Company may
                limit the amount of the withdrawal to a payment system with
                amount of the deposits came on the Client's account from that
                payment system. The Company may, at its discretion, make
                exceptions to this rule and withdraw Client money to other
                payment systems, but the Company may at any time ask the Client
                for the payment information for the other payment systems, and
                the Client must provide the Company with that payment
                information.;
              </p>
            </div>
          </li>

          <li>
            <span class="number">5.2</span>
            <p>
              A Request for Withdrawal is executed by transferring the funds to
              the Client’s External Account by an Agent authorized by the
              Company.
            </p>
          </li>

          <li>
            <span class="number">5.3</span>
            <p>
              The Client shall make a Request for Withdrawal in the currency of
              the deposit. If the deposit currency is different from the
              transfer currency, the Company will convert transfer amount into
              the transfer currency at the exchange rate established by the
              Company as of the time when the funds are debited from the
              Client’s Account.
            </p>
          </li>

          <li>
            <span class="number">5.4</span>
            <p>
              The currency in which the Company makes transfers to the Client’s
              External Account may be displayed in the Client’s Dashboard,
              depending on the currency of the Client’s Account and the
              withdrawal method.
            </p>
          </li>

          <li>
            <span class="number">5.5</span>
            <p>
              The conversion rate, commission and other expenses related to each
              withdrawal method are set by the Company and may be changed at any
              time at the Company’s sole discretion. The exchange rate may
              differ from the currency exchange rate set by the authorities of a
              particular country and from the current market exchange rate for
              the relevant currencies. In the cases established by Payment
              Service Providers, funds may be withdrawn from the Client’s
              Account in a currency that is different from the currency of the
              Client’s External Account.
            </p>
          </li>

          <li>
            <span class="number">5.6</span>
            <p>
              The Company reserves the right to set minimum and maximum
              withdrawal amounts depending on the withdrawal method. These
              restrictions will be set out in the Client's Dashboard.
            </p>
          </li>

          <li>
            <span class="number">5.7</span>
            <p>
              The withdrawal order is deemed accepted by the Company if it is
              created in the Client’s Dashboard, and is displayed in the Balance
              History section and in the Company’s system for accounting
              clients’ requests. An order created in any manner other than that
              specified in this clause will not be accepted and executed by the
              Company.
            </p>
          </li>

          <li>
            <span class="number">5.8</span>
            <p>
              The funds will be withdrawn from the Client's account within five
              (5) business days.
            </p>
          </li>

          <li>
            <span class="number">5.9</span>
            <p>
              If the funds sent by the Company pursuant to a Request for
              Withdrawal have not arrived in the Client's External Account after
              five (5) business days, the Client may ask the Company to
              investigate this transfer.
            </p>
          </li>

          <li>
            <span class="number">5.10</span>
            <p>
              If the Client has made an error in the payment information when
              drawing up a Request for Withdrawal that resulted in a failure to
              transfer money to the Client's External Account, the Client will
              pay a commission for resolving the situation.
            </p>
          </li>

          <li>
            <span class="number">5.11</span>
            <p>
              The Client's profit in excess of the funds deposited by the Client
              may be transferred to the Client's External Account only by a
              method agreed by the Company and Client, and if the Client made a
              deposit to his/her account by a certain method, the Company has
              the right to withdraw a previous deposit of the Client by the same
              method.
            </p>
          </li>

          <li>
            <span class="number">6</span>
            <p>
              Payment methods for withdrawals
            </p>
          </li>

          <li>
            <span class="number">6.1</span>
            <p>
              6.1 Bank transfer.
            </p>
          </li>

          <li>
            <span class="number">6.1.1</span>
            <p>
              The Client may send a Request for Withdrawal by bank wire transfer
              at any time if the Company accepts this method at the time of
              funds transfer.
            </p>
          </li>

          <li>
            <span class="number">6.1.2</span>
            <p>
              The Client may make a Request for Withdrawal only to a bank
              account opened in his/her name. The Company will not accept and
              execute orders to transfer money to a bank account of a third
              party.
            </p>
          </li>

          <li>
            <span class="number">6.1.3</span>
            <div>
              <p>
                The Company must send the money to the Client's bank account in
                accordance with the information in the Request for Withdrawal if
                the conditions of clause 7.1.2. of this Regulation are met.
              </p>

              <p>
                The Client understands and agrees that the Company assumes no
                liability for the time a bank transfer takes.
              </p>
            </div>
          </li>

          <li>
            <span class="number">6.2</span>
            <p>
              Electronic transfer.
            </p>
          </li>

          <li>
            <span class="number">6.2.1</span>
            <p>
              6.2.1 The Client may send a Request for Withdrawal by electronic
              transfer at any time if the Company uses this method when the
              transfer is made.
            </p>
          </li>

          <li>
            <span class="number">6.2.2</span>
            <p>
              The Client may make a Request for Withdrawal only to his/her
              personal electronic payment system wallet.
            </p>
          </li>

          <li>
            <span class="number">6.2.3</span>
            <p>
              The Company must send money to the Client's electronic account in
              accordance with the information in the Request for Withdrawal.
            </p>
          </li>

          <li>
            <span class="number">6.2.4</span>
            <p>
              The Client understands and acknowledges that the Company is not
              responsible for the time an electronic transfer takes or for the
              circumstances resulting in a technical failure during the transfer
              if they occurred through no fault of the Company.
            </p>
          </li>

          <li>
            <span class="number">6.3</span>
            <p>
              The Company may, at its discretion, offer the Client other methods
              for withdrawing money from the Client's account. This information
              is posted in the Dashboard.
            </p>
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style scoped>
header {
  background-attachment: fixed;
  padding: 0;
  padding-bottom: 10vw;
  background-color: rgb(8, 9, 21);
  background-repeat: no-repeat;
  background-size: cover;
}

.showcase-div {
  padding: 0 10vw;
}

header h1 {
  font-size: 3.75rem;
  font-family: 'Roboto';
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  text-align: center;
  font-weight: 1000;
  padding: 10vh 0;
  color: white;
  margin: 0;
}

.list {
  background: rgb(8, 9, 21);
}

.list p,
.list span {
  color: #fff;
}

.md-list {
  background-color: transparent;
}

li {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 13fr;
}

li p {
  text-align: start;
  font-size: 1.3rem;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
}

@media (max-width: 400px) {
  .showcase-div {
    padding: 0;
  }

  ul {
    padding: 1rem;
  }

  li {
    grid-gap: 1rem;
  }

  li p {
    font-size: 1rem;
    padding: 0.5rem;
  }
}
</style>
