<template>
  <div>
    <div
      id="slide-me-up"
      class="tradingview-widget-container"
      style="width: 100%; height: 46px;"
    >
      <iframe
        scrolling="no"
        allowtransparency="true"
        frameborder="0"
        src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22OANDA%3ASPX500USD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22OANDA%3ANAS100USD%22%2C%22title%22%3A%22Nasdaq%20100%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22BTC%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22ETH%2FUSD%22%7D%5D%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22binaryiqtrade.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%7D"
        style="box-sizing: border-box; height: 46px; width: 100%;"
      ></iframe>
    </div>
    <section>
      <md-avatar class="md-avatar-icon md-primary mail-icon">
        <md-icon>contact_mail</md-icon>
      </md-avatar>

      <h1 class="first-header">Contact Us</h1>
      <p class="first-para">We'll get back to you as soon as possible</p>

      <div class="contact-div">
        <ul>

          <li>
            <md-icon>mail_outline</md-icon>
            <span>helpdesk.binaryiqfxtrade@gmail.com</span>
          </li>

          <li>
            <md-icon>my_location</md-icon>
            <span>19 West 34th Street, New York 10001</span>
          </li>
        </ul>
      </div>

      <div class="question">
        <h3>Have a Question?</h3>

        <div class="input-divs">
          <div class="left-inner-addon input-container">
            <label for="">First Name *</label>
            <md-icon class="icon">contacts</md-icon>
            <input type="password" class="form-control" />
          </div>

          <div class="left-inner-addon input-container">
            <label for="">Last Name *</label>
            <md-icon class="icon">contacts</md-icon>
            <input type="password" class="form-control" />
          </div>

          <div class="left-inner-addon input-container full">
            <label for="">Email Address *</label>
            <md-icon class="icon">mail_outline</md-icon>
            <input type="password" class="form-control" />
          </div>

          <div class="left-inner-addon input-container full">
            <label for="">Message *</label>
            <md-icon class="icon">text_fields</md-icon>
            <input type="password" class="form-control" />
          </div>

          <div class="btn-div full">
            <md-button class="md-raised md-primary sub-btn">SUBMIT</md-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Contact',
};
</script>

<style scoped>
section {
  padding: 4rem 0;
  background: #fff;
  background-color: #fafafa;
}

.first-header {
  font-size: 2.125rem;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  margin: 5px 0;
}

.first-para {
  font-size: 1rem;
  font-family: initial;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 5px 0;
}

.md-list {
  display: block;
}

.contact-div {
  padding: 1.5rem;
}

.contact-div ul {
  list-style: none;
  border: 2px solid rgb(18, 29, 51);
  padding: 5px 3px;
}

.contact-div li {
  display: grid;
  grid-template-columns: 1fr 13fr;
  grid-gap: 1rem;
  padding: 0.5rem 0;
}

.contact-div li span {
  text-align: start;
}

.input-container {
  padding-bottom: 1em;
}
.left-inner-addon {
  position: relative;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}
.left-inner-addon input {
  padding-left: 35px !important;
}
.left-inner-addon .icon {
  position: absolute;
  /* padding: 12px 12px; */
  pointer-events: none;
  font-size: 1.3rem;
  margin: 10px;
  color: blue;
}

input {
  width: 100%;
  padding: 18px !important;
  margin: 0em !important;
  box-sizing: border-box;
  font-size: 1rem;
  border: 0.3px solid rgb(0 0 0 / 25%);
  background: transparent;
}

input :focus {
  border: 1px solid blue;
}

label {
  position: absolute;
  top: -13px;
  background: #fafafa;
  padding: 5px;
  left: 8px;

  color: rgba(0, 0, 0, 0.54);

  font-size: 0.8rem;

  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.mail-icon {
  background: blue !important;
}

.question h3 {
  font-size: 1.5rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

.sub-btn {
  background-color: #3f51b5 !important;
}

.btn-div {
  display: flex;
  justify-content: start;
  width: 95%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .contact-div {
    width: 45%;
    margin: 0 auto;
  }

  .input-divs {
    display: grid;
    width: 40%;
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
  }

  .full {
    grid-column: 1/3;
  }

  .form-control {
    width: 100%;
  }

  .left-inner-addon {
    width: 100%;
  }

  .sub-btn {
    margin: 0;
  }

  .btn-div {
    width: 100%;
  }
}
</style>
