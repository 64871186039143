<template>
  <div class="page-container md-layout-column">
    <md-toolbar class="md-primary">
      <span class="md-title">Binary IQ FX TRADE</span>

      <div class="md-toolbar-section-end">
        <md-menu class="menu">
          <router-link to="/">Home</router-link>
          <router-link to="/contact">Contact</router-link>
          <router-link to="/plans">Plans</router-link>
          <router-link to="/kyc-policy">KYC Policy</router-link>
          <router-link to="/privacy-policy">Privacy Policy</router-link>
          <router-link to="/payment-policy">Payment Policy</router-link>
          <div>
            <router-link class="link" to="/register">SIGN UP</router-link>
            <router-link class="link" :to="redirectTo">LOGIN</router-link>
          </div>
        </md-menu>

        <md-button class="md-icon-button" @click="showNavigation = true">
          <md-icon>menu</md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <md-drawer :md-active.sync="showNavigation" md-swipeable>
      <md-list>
        <md-list-item class="list-item">
          <md-icon class="md-icon">home</md-icon>
          <router-link to="/" class="md-list-item-text">Home</router-link>
        </md-list-item>

        <md-divider></md-divider>

        <md-list-item class="list-item">
          <md-icon class="md-icon">account_balance</md-icon>
          <router-link to="/plans" class="md-list-item-text">Plans</router-link>
        </md-list-item>

        <md-divider></md-divider>

        <md-list-item class="list-item">
          <md-icon class="md-icon">contact_mail</md-icon>
          <router-link to="/contact" class="md-list-item-text"
            >Contact Us</router-link
          >
        </md-list-item>

        <md-divider></md-divider>

        <md-list-item class="list-item">
          <md-icon class="md-icon">gavel</md-icon>
          <router-link to="/privacy-policy" class="md-list-item-text"
            >Privacy Policy</router-link
          >
        </md-list-item>

        <md-divider></md-divider>

        <md-list-item class="list-item">
          <md-icon class="md-icon">dns</md-icon>
          <router-link to="/payment-policy" class="md-list-item-text"
            >Payment Policy</router-link
          >
        </md-list-item>

        <md-divider></md-divider>

        <md-list-item class="list-item  link-item">
          <router-link to="/register" class="md-list-item-text link"
            >SIGN UP</router-link
          >
        </md-list-item>

        <md-list-item class="list-item link-item">
          <router-link :to="redirectTo" class="md-list-item-text link"
            >LOGIN</router-link
          >
        </md-list-item>
      </md-list>
    </md-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Navbar',
  data: () => ({
    showNavigation: false,
    showSidepanel: false,
  }),

  computed: {
    ...mapGetters(['currentUser']),
    redirectTo() {
      return this.currentUser ? '/account/dashboard' : '/login';
    },
  },
};
</script>

<style scoped>
.page-container {
  /* min-height: 300px; */
  height: auto;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(#000, 0.12);
  z-index: 2;
}

.md-drawer {
  width: 320px;
  max-width: calc(108vw - 120px);
  position: fixed;
}

.md-content {
  padding: 16px;
}
.md-app {
  border: 1px solid rgba(#000, 0.12);
}

.md-primary {
  background-color: rgb(18, 29, 51) !important;
  padding: 12px;
}

nav {
  padding: 14px 16px;
}

.md-primary h5 {
  font-size: 24px;
  font-family: 'Arvo', serif;
}

.content {
  height: 100vh;
  padding: 0;
}

nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed;
  top: 0; */
}

.md-title {
  font-size: 1.5rem;
  font-family: 'Arvo';
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

.md-toolbar-section-end {
  padding-right: 5px;
}

.menu {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  align-items: center;
  display: none;
}

.menu a {
  font-family: initial;
  color: #fff !important;
  font-size: 1.2rem;

  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.menu .link {
  padding: 6px 16px;
  font-size: 1rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  margin-left: 25px;
}

.list-item {
  padding: 16px;
}

.list-item a {
  padding: 8px 16px;
  font-size: 1rem;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: black !important;
}

.list-item .link {
  font-size: 0.775rem;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 1.75;
  padding-left: 32px;
  padding-right: 32px;
  color: #fff !important;
}

.list-item .md-icon {
  margin-right: 5px !important;
}

.link-item {
  padding: 16px 2px 0 2px;
}

@media (min-width: 768px) {
  .md-icon-button {
    display: none;
  }

  .md-primary {
    padding: 15px;
  }

  .menu {
    display: flex;
  }
}
</style>
